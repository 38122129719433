<template>
  <div class="base-list-page">
    <el-form
      :inline="true"
      :model="searchForm"
      class="bysearchForm"
      @keyup.enter.native="getDataList()"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input v-model="searchForm.name" placeholder="用户名" clearable />
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()"> 查询 </el-button>
          <el-button
            v-if="isAuth('sys:usertoken:offline')"
            type="danger"
            :disabled="dataListSelections.length <= 0"
            @click="offlineHandle()"
          >
            批量下线
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      style="width: 100%"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      />
      <el-table-column
        prop="userId"
        header-align="center"
        align="center"
        label="用户名"
      >
        <template slot-scope="scope">
          <span>{{ transUser(scope.row.userId) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="updateTime"
        header-align="center"
        align="center"
        label="登录时间"
      />
      <el-table-column
        prop="expireTime"
        header-align="center"
        align="center"
        label="过期时间"
      />
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('sys:usertoken:offline')"
            type="text"
            size="small"
            @click="offlineHandle(scope.row.userId)"
          >
            强制下线
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchForm: {
        name: '',
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
    };
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/sys/usertoken/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          name: this.searchForm.name,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 下线
    offlineHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.userId;
          });
      this.$confirm(`确定对[id=${ids.join(',')}]进行[强制下线]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/sys/usertoken/offline',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
